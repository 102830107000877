const theme = {
  colors: {
    //mo: changed
    // themeColor: "#ffd2d1",
    // themeColor: "#a07c54", // like it # 1
    // themeColor: "#b26f4c",
    // themeColor: "#bb6f3e",
    themeColor: "#0071ac", // grey blue
    // themeColor: "#0690cf", // blue
    // themeColor: "#d77456", // like it #2 - less manly // main orange
    // themeColor: "#b8704c", // like it #3 - more manly than #2
    //mo: end changed
    textColor: "#090606",
    headingColor: "#090606",
    borderColor: "#e3e7e8",
    secondaryColor: "#0e1b47",
    hoverColor: "#d77456",
    hoverColorTwo: "#ff4d4d",
    appricot: "#eb7e84",
    shark: "#929496",
    gray: "#888c8e",
    silver: "#ababab",
    nevada: "#63696a",
    white: "#ffffff",
    black: "#000000",
    primary: "#004395",
    secondary: "#d2a98e",
    success: "#28a745",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
    //mo: changed
    headerColor: "#1d3b59",
    // headerColorTransparent: "rgba(11, 23, 35, 0.8)", // rgba equivelant headercolor
    // headerColorTransparent: "rgba(16, 33, 50, 0.7)", // rgba brighter than above to show when transparent
    headerColorTransparent: "rgba(24, 48, 72, 0.7)", // rgba brighter than above to show when transparent
    navItemHoverBorderColor: "#ffaa3b",
    // orangeColor: "#ffaa3b",
    // orangeColor: "#eb5c3c",
    // orangeColor: "#eb5c3c",
    // orangeColor: "#CC5500",
    orangeColor: "#c2784f",
    //mo: end changed
  },
  lineHieghts: {
    text: 1.714,
    heading: 1.857,
  },
  fonts: {
    text: "Montserrat",
    heading: "Montserrat",
    marck: "Marck Script",
  },
  fontSizes: ["10px", "12px", "14px", "16px", "20px", "24px", "32px"],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  space: [0, 4, 8, 16, 32, 64],
  breakpoints: ["481px", "576px", "768px", "992px", "1200px", "1400px"],
  transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
}

theme.fontSize = {
  text: theme.fontSizes[2],
  h1: ["26px", "30px", "34px"],
  h2: ["24px", "28px", "32px"],
  h3: ["22px", "26px", "30px"],
  h4: ["20px", "22px", "24px"],
  h5: ["16px", "18px"],
  h6: ["14px", "16px"],
}

export const device = {
  xxsmall: `@media screen and (min-width: ${theme.breakpoints[0]})`,
  xsmall: `@media screen and (min-width: ${theme.breakpoints[1]})`,
  small: `@media screen and (min-width: ${theme.breakpoints[2]})`,
  medium: `@media screen and (min-width: ${theme.breakpoints[3]})`,
  large: `@media screen and (min-width: ${theme.breakpoints[4]})`,
  xlarge: `@media screen and (min-width: ${theme.breakpoints[5]})`,
  xxlarge: `@media screen and (min-width: ${theme.breakpoints[6]})`,
}

export default theme
