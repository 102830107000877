import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const OffCanvasHeaderWrap = styled.div`
  //mo: changed
  // background-color: #ffffff;
  background-color: ${themeGet("colors.headerColor")};
  //mo: end changed
  padding: 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const OffCanvasHeaderLeft = styled.div`
  flex: 1 0 auto;
`

export const OffCanvasHeaderRight = styled.div`
  flex: 1 0 auto;
  text-align: right;
`
