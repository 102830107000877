import styled, { css } from "styled-components"
import { themeGet } from "@styled-system/theme-get"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

export const NavLinkStyled = css`
  display: inline-block;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  text-transform: uppercase;
  //mo: changed
  //mo: create border-top so menu items don't jump as a result of hover and active states
  border-top: 2px solid transparent;
  &:hover {
    border-top: 2px solid ${themeGet("colors.navItemHoverBorderColor")};
  }
  //mo: end changed
`

export const AnchorTag = styled.a`
  ${NavLinkStyled}
`

export const NavLinkWrap = styled(Link)`
  ${NavLinkStyled}
`

export const SmoothScroll = styled(AnchorLink)`
  ${NavLinkStyled}
`
