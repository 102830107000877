import styled from "styled-components"
import { space } from "styled-system"
import { themeGet } from "@styled-system/theme-get"

export const FooterWrap = styled.footer`
  background-color: ${themeGet("colors.headerColorTransparent")};
  color: #fff;
`

export const FooterTop = styled.div`
  //mo: changed
  border-top: 1px solid ${themeGet("colors.borderColor")};
  //mo: end changed
  padding-top: 60px;
  padding-bottom: 33px;
`

export const FooterBottom = styled.div`
  padding-bottom: 65px;
`

export const FooterWidget = styled.div`
  ${space}
`

export const AddressWidget = styled.div``
