import React from "react"
import PropTypes from "prop-types"
import Anchor from "../shared/anchor"
//mo: changed
import DarkLogo from "../../assets/img/envyforge-marquee-light.png"
import WhiteLogo from "../../assets/img/envyforge-marquee-light.png"
import { LogoWrap } from "./logo.stc"

const Logo = props => {
  return (
    <LogoWrap {...props}>
      <Anchor path="/">
        <img src={DarkLogo} alt="Logo" className="dark-logo" height="36" />
        <img src={WhiteLogo} alt="Logo" className="white-logo" height="36" />
      </Anchor>
    </LogoWrap>
  )
}

Logo.propTypes = {
  transparentBG: PropTypes.bool,
}

export default Logo
